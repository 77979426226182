import React from "react";
import { useAuth } from "./useAuth";
import { ArrayUtil } from "../util/Array.util";
import CatalogActivity from "../models/CatalogActivity";
import { Cache } from "../util/Cache.util";

/**
 * @typedef {Number|String} Stringable
 */

/**
 * @typedef {Object} LogParams
 *  @property {String} [url]
 *  @property {Number} [page.id]
 *  @property {String} [page.type.code]
 *  @property {String} [action]
 *  @property {Number} [asset]
 *  @property {Number} [item]
 */

/**
 * @typedef {Object} LogOptions
 *  @property {"short"|"standard"} [limit.type] - Shortcuts: "short" = 1m; "standard" = 5m
 *  @property {Stringable|Stringable[]} [limit.key]
 *  @property {Number} [limit.delay]
 *  @property {"milliseconds" | "seconds" | "minutes"} [limit.delayUnit]
 */

const LogProviderContext = React.createContext({
	/**
	 * @param {LogParams} params
	 * @param {LogOptions} [options]
	 * @returns Promise
	 */
	log: (params, options) => {},
	/**
	 * @param {LogParams} params
	 * @returns Promise
	 */
	logPageView: (params) => {},
});

const logQueue = new Set();

let logBatchTimeoutId;

export const useLog = () => {
	const context = React.useContext(LogProviderContext);
	if (!context) {
		throw new Error("useAuth must be used within a LogProvider");
	}
	return context;
};

export const LogProvider = ({ children }) => {
	const AuthContext = useAuth();

	/**
	 * @param {LogParams} params
	 * @param {LogOptions} [options]
	 */
	const log = async (params, options = undefined) => {
		if (process.env.REACT_APP_DEV_MODE || AuthContext.organizationMember?.role?.is_administrator) {
			return;
		}

		if (options?.limit?.type) {
			switch (options.limit.type) {
				case "standard":
					options.limit.delay = 5;
					options.limit.delayUnit = "minutes";
					break;
				case "short":
					options.limit.delay = 1;
					options.limit.delayUnit = "minutes";
					break;
			}
		}

		const now = new Date();
		const storedTimeoutsKey = "log-timeouts";
		const limitKey = Array.isArray(options?.limit?.key) ? options.limit.key.filter(f=>f).join("|") : options?.limit?.key;
		// clear limit of expired; check remaining, halt if present, store cleaned data
		if (limitKey) {
			const storedLogTimeouts = Cache.Session().get(storedTimeoutsKey);
			if (storedLogTimeouts) {
				const storedLogTimeoutEntries = Object.entries(storedLogTimeouts);
				const filteredEntries = storedLogTimeoutEntries
					.filter(([key, it]) => it.expires_on > now);
				const logTimeouts = new Map(filteredEntries);
				// if all stored entries are valid, no need to re-store
				if (logTimeouts.size !== storedLogTimeoutEntries.length) {
					Cache.Session().set(storedTimeoutsKey, logTimeouts);
				}
				// if limit still valid, prevent log
				if (logTimeouts.has(limitKey)) {
					return;
				}
			}
		}

		const url_object = new URL(params.url);
		const slug = url_object.pathname + url_object.search;
		const payload = {
			page: params.page.id,
			action: params.action,
			asset: params.asset,
			item: params.item,
			url: params.url,
			slug,
			type: params.page.type?.code
		};

		logQueue.add(payload);
		startLogBatch();

		// save limit
		if (limitKey && options?.limit?.delay) {
			const storedLogTimeouts = Cache.Session().get(storedTimeoutsKey) ?? {};
			const logTimeouts = new Map(Object.entries(storedLogTimeouts));
			const expires = new Date(now);
			const delay = +options.limit.delay;
			switch (options.limit.delayUnit) {
				case "minutes":
					expires.setMinutes(expires.getMinutes() + delay);
					break;
				case "seconds":
					expires.setSeconds(expires.getSeconds() + delay);
					break;
				case "milliseconds":
					expires.setMilliseconds(expires.getMilliseconds() + delay);
					break;
			}
			logTimeouts.set(limitKey, { expires_on: expires.getTime() });
			Cache.Session().set(storedTimeoutsKey, logTimeouts);
		}
	}

	/**
	 * @param {LogParams} params
	 */
	const logPageView = params => {
		return log(params, {
			limit: {
				type: "standard",
				key: [params.page.id, params.action, params.item],
			}
		});
	}

	const processLogQueue = async () => {
		const chunks = ArrayUtil.Collate(Array.from(logQueue), 5).at(0);
		await Promise.allSettled(chunks.map(it => {
			logQueue.delete(it);
			const activity = new CatalogActivity();
			activity.setProperties(it);
			return activity.save();
		}));

		logBatchTimeoutId = undefined;
		if (logQueue.size) {
			startLogBatch();
		}
	}

	const startLogBatch = () => {
		logBatchTimeoutId ??= setTimeout(() => processLogQueue(), 5_000);
	}


	const value = {
		log,
		logPageView,
	};

	return <LogProviderContext.Provider value={value}>{children}</LogProviderContext.Provider>;
};
