import React from "react";


/**
 * @callback SidebarContext.open
 * @param {SidebarContentType} type
 */

/**
 * @typedef SidebarContextDef
 * @property {Boolean} isOpen
 * @property {SidebarContentType} type
 *
 * @property {SidebarContext.open} open
 *
 * @desc Closes/Hides sidebar
 * @property {function} close
 *
 * @desc Closes sidebar and resets content
 * @property {function} exit
 */

/** @type SidebarContextDef */
const SidebarContextDef = {
	isOpen: undefined,
	type: undefined,
	open: type => {},
	close: () => {},
	exit: () => {},
};

/**
 * @type {React.Context<SidebarContextDef>}
 */
const SidebarContext = React.createContext(SidebarContextDef);


/**
 * @enum {string}
 */
export const SidebarContentType = {
	Proposal: "proposal",
	Bookmark: "bookmark",
	Customer: "customer",
};

export const useSidebarContext = () => {
	const context = React.useContext(SidebarContext);
	if (!context) {
		throw new Error(
			"useSidebarContext must be used within a SidebarContextProvider"
		);
	}
	return context;
};

export const SidebarContextProvider = ({ children }) => {
	const [toggleSidebar, setToggleSidebar] = React.useState(undefined);
	const [sidebarContent, setSidebarContent] = React.useState(undefined);
	
	/**
	 * @param {SidebarContentType} type
	 */
	const openSidebar = type => {
		type && setSidebarContent(type);
		setToggleSidebar(true);
	}
	
	const closeSidebar = () => {
		setToggleSidebar(false);
	}
	
	const exitSidebar = () => {
		closeSidebar();
		const timing = window.getComputedStyle(document.querySelector("#_boss"))
			.getPropertyValue("--sidebar-reveal-timing-ms");
		setTimeout(() => setSidebarContent(undefined), +timing);
	}
	
	const value = {
		isOpen: toggleSidebar,
		type: sidebarContent,
		
		open: openSidebar,
		close: closeSidebar,
		exit: exitSidebar,
	};

	return (
		<SidebarContext.Provider value={value} displayName="Sidebar Context">
			{children}
		</SidebarContext.Provider>
	);
};
