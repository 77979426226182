import LiveApiClient from "../api/LiveApiClient";

/**
 * This class will try to perform all CRUD properties for an API endpoint.
 */
export default class ApiDrivenModel {
	constructor(properties) {
		this.data = {
			self: this,
		};
		this._system = {
			loaded_values: { ...properties },
			listeners: {}
		};

		properties && this.setProperties(properties);
	}

	setProperties(properties) {
		for (const property in properties) {
			this.setProperty(property, properties[property]);
		}

	}
	async setProperty(property, value) {
		this[property] = value;
		this.updateData(property, value);

		return value;
	}
	getLoadedValues() {
		return this._system.loaded_values;
	}
	updateData(property, value) {
		// Ensure data is set (resolves issue with setting data for ProposalItem
		this.data = this.data || { self: this };

		// Convert all members of this object to
		if (typeof value === "object" && value instanceof Array) {
			this.data[property] = value.map((child) => child.data || child);
		} else {
			this.data[property] = value?.data || value;
		}
		this.dispatchEvent("change", { property, value: this.data[property] });
	}
	saveProperty(property) {
		const data = {};
		data[property] = this[property];

		const options = {
			headers: {
				...ApiDrivenModel.GetApiHeaders(),
				paths: this.constructor.PATHS ? JSON.stringify(this.constructor.PATHS) : '',
			},
		};

		return new Promise((resolve, reject) => {
			LiveApiClient.put(
				this.constructor.ENDPOINT + "/" + this.id,
				data,
				options
			).then(response => {
				this.setProperties(response.data);
				this.setLoadedValues(response.data);
				resolve(response.data);
			})
				.catch(reject);
		});
	}
	toArray() {
		const data = { ...this };
		const ignore = ['data', '_system'];

		// unset any objects not in current scope
		for (const key in data) {
			let value = data[key];
			if (ignore.includes(key)) {
				delete data[key];
			} else if (typeof data[key] === 'object') {
				// if it has a simple id, use that
				if (value?.id) {
					data[key] = value?.id;
				} else if (Array.isArray(value)) {
					delete data[key];
				}
			}
		}

		return data;
	}
	setLoadedValues(values) {
		this._system.loaded_values = values;
	}

	toData() {
		return this.toArray();
	}

	cleanForSave() {
		delete this.data;
	}

	/**
	 * @param {Boolean} options.withPublicApi
	 * @desc Performs either a PUT or POST request
	 */
	async save(options = undefined) {
		const fn = this.id ? "put" : "post";

		this.cleanForSave();
		const data = this.toData();

		// Only submit new data
		const loaded = this.getLoadedValues();
		for (const key in loaded) {
			let value_a = data[key];
			let value_b = loaded[key];

			// prevent circular reference error during JSON stringify
			if (value_a && typeof value_a === "object" && value_a.data) {
				delete value_a.data
			}
			if (value_b && typeof value_b === "object" && value_b.data) {
				delete value_b.data
			}

			// only need to compare values on updates
			if (this.id) {
				if (typeof (value_a) === 'object' && value_a?.constructor?.name !== 'Array') {
					value_a = JSON.stringify(value_a);
				}
				if (typeof (value_b) === 'object' && value_b?.constructor?.name !== 'Array') {
					value_b = JSON.stringify(value_b);
				}
				if (value_a === value_b) {
					delete data[key];
				}
			}
		}

		const api_options = {
			headers: {
				...ApiDrivenModel.GetApiHeaders(options?.withPublicApi ? ApiDrivenModel.Get_Public_Api_Context() : undefined),
				paths: (this.constructor.PATHS ? JSON.stringify(this.constructor.PATHS) : '')
					|| (this.constructor.LOAD_PATHS ? JSON.stringify(this.constructor.LOAD_PATHS) : ''),
			},
		};

		const response = await LiveApiClient[fn](
			this.constructor.ENDPOINT + (this.id ? "/" + this.id : ""),
			data,
			api_options
		);
		// if server fails without failure response code, try to parse; will fail on server failure
		const parsedData = typeof response.data === "string" ? JSON.parse(response.data) : response.data;
		this.setProperties(parsedData);
		this.setLoadedValues(parsedData);
		return parsedData;
	}

	//Removes a single row from the database given an ID
	remove() {
		const options = {
			headers: ApiDrivenModel.GetApiHeaders(),
		};
		return new Promise((resolve, reject) => {
			LiveApiClient.delete(this.constructor.ENDPOINT + "/" + this.id, options)
				.then((response) => {
					this.dispatchEvent("change");
					resolve(response.data);
				})
				.catch(reject);
		});
	}

	addEventListener(eventType, fn) {
		eventType = eventType.toLowerCase();
		if (!this._system.listeners[eventType]) {
			this._system.listeners[eventType] = [];
		}
		this._system.listeners[eventType].push(fn);
	}

	dispatchEvent(eventType, payload = {}) {
		eventType = eventType.toLowerCase();

		const event = new Event(eventType);
		for (const key in payload) {
			event[key] = payload[key];
		}

		// Trigger events triggered where addEventListener() was used
		this._system.listeners[eventType] &&
			this._system.listeners[eventType].forEach((fn) => {
				if (fn(event) === false) {
					return false;
				}
			});

		// Trigger events triggered directly using eventType
		// ie: this.onchange = () => alert('hi');
		if (this[eventType] && this[eventType](event) === false) {
			return false;
		}
	}

	getClass() {
		return this.constructor.name;
	}

	getMeta() {
		return {
			id: this.id,
		}
	}

	static async Search(apiContext, params = undefined, call_options = {}) {
		params = params || {};

		const endpoint = params?.endpoint || this.ENDPOINT;
		delete params.class;
		delete params.endpoint;

		if (!endpoint) {
			return console.warn(`No ENDPOINT set`);
		}

		// allow filter param to be map of directly-equals, key:value pairs
		if (params.filter && !Array.isArray(params.filter) && typeof params.filter === "object") {
			params.filter = Object.entries(params.filter).map(([key, value]) => ({ property: key, value }));
		}

		params = Object.assign({}, params || {});
		for (const key in params) {
			if (Array.isArray(params[key])) {
				params[key] = JSON.stringify(params[key]);
			}
		}
		const paths = params.paths ??= this.SEARCH_PATHS ?? this.PATHS;
		delete params.paths;
		const paths_stringified = JSON.stringify(paths);

		const options = {
			headers: {
				...this.GetApiHeaders(apiContext),
				paths: paths_stringified,
				'cache-control': (call_options?.cache !== false) && !localStorage.getItem('nocache') && 'private',
			},
			params: { ...params, paths: paths_stringified },
		};

		const { data } = await LiveApiClient.get(endpoint, options);
		return data?.map(it => new this(it));
	}

	static async SearchBy(params = undefined, call_options = undefined, withPublicApi = false) {
		return this.Search(
			withPublicApi ? this.Get_Public_Api_Context() : this.CURRENT_CONTEXT,
			params,
			{ cache: false, ...(call_options ?? {}) }
		);
	}

	static async FetchBy(params = undefined, call_options = undefined, withPublicApi = false) {
		return this.Fetch(
			withPublicApi ? this.Get_Public_Api_Context() : this.CURRENT_CONTEXT,
			params,
			{ cache: false, ...(call_options ?? {}) }
		);
	}

	static async Fetch(apiContext, params = undefined, call_options = {}) {
		params = params || {};
		params.limit = 1;

		const results = await this.Search(apiContext, params, call_options);
		return results.at(0);
	}

	static async Call(name, payload) {
		const options = {
			headers: this.GetApiHeaders(this.CURRENT_CONTEXT),
		};

		const result = await LiveApiClient.post(`${this.ENDPOINT}/fn/${name}`, { payload }, options);
		if (result.status !== 200) {
			throw new Error(result.data);
		}

		let data;
		try {
			data = typeof result.data === "string" ? JSON.parse(result.data) : result.data;
		}
		catch (e) {
			throw new Error("Bad Response");
		}

		return data;
	}
	
	static async FromId(id) {
		return this.FetchBy({
			filter: { id },
		});
	}

	static async Load(apiContext, id, params = undefined, call_options = {}) {
		id ??= this.id;

		const endpoint = this.ENDPOINT;

		if (!endpoint) {
			return console.warn(`No ENDPOINT set`);
		}

		params ??= {};
		params.paths = JSON.stringify(this.LOAD_PATHS ?? this.PATHS);

		const options = {
			headers: this.GetApiHeaders(apiContext),
			params,
		};
		options.headers['Cache-Control'] = !call_options.cache || localStorage.getItem('nocache') ? "no-cache" : 'private';

		return LiveApiClient.get(`${endpoint}/${id}`, options)
			.then(response => new this(response.data))
			.catch(error => error);
	}

	static SaveAll(instance_array) {
		const data = instance_array.map(instance => instance.toArray());
		const options = {
			headers: {
				...ApiDrivenModel.GetApiHeaders(),
				paths: (this.PATHS ? JSON.stringify(this.PATHS) : '')
					|| (this.LOAD_PATHS ? JSON.stringify(this.LOAD_PATHS) : ''),
			},
		};

		return new Promise((resolve, reject) => {
			LiveApiClient.put(
				this.ENDPOINT + "/",
				data,
				options
			).then(response => {
				// TODO: Use a key passed from original save request to leverage original instances
				const return_instances = response.data.map(instance_data => {
					const new_instance = new this(instance_data);
					new_instance.setLoadedValues(instance_data);
					return new_instance;
				});
				resolve(return_instances);
			}).catch(reject);
		});
	}

	static Request(apiContext, payload = undefined, options = undefined) {
		options ??= {};
		const endpoint = options.endpoint || this.ENDPOINT;
		delete options.class;
		delete options.endpoint;

		payload = Object.assign({}, payload || {});
		Object.keys(payload)
			.filter(key => Array.isArray(payload[key]))
			.forEach(key => payload[key] = JSON.stringify(payload[key]));

		const headers = this.GetApiHeaders(apiContext);

		const method = options.method ? options.method.toLowerCase() : 'get';
		const args = method === 'get'
			? [endpoint, { params: payload, headers }]
			: [endpoint, payload, { headers }];
		return new Promise((resolve, reject) => {
			LiveApiClient[method].apply(null, args)
				.then(response => resolve(new this(response.data)))
				.catch(error => reject(error));
		});
	}

	static Get_Public_Api_Context() {
		return { "api-key": 1 };
	}

	static GetApiHeaders(apiContext) {
		apiContext = apiContext || this.CURRENT_CONTEXT;
		if (!apiContext) {
			return this.Get_Public_Api_Context();
		}
		// Remember for next time
		this.CURRENT_CONTEXT = apiContext;

		return {
			"account": apiContext.account,
			"api-key": apiContext.apiKey || 1,
		};
	}
}
