export class ArrayUtil
{
	/**
	 * @desc Divide array @seed into a collection of arrays of length @count
	 * @param {Array} seed
	 * @param {Number} count
	 *
	 * @example Collate([1,2,3,4,5,6], 2) => [ [1,2], [3,4], [5,6] ]
	 * @example Collate([1,2,3,4,5,6], 3) => [ [1,2,3], [4,5,6] ]
	 * @example Collate([1,2,3,4,5,6,7], 3) => [ [1,2,3], [4,5,6], [7] ]
	 */
	static Collate(seed, count) {
		if (!seed || !seed.length || !count || count <= 1 || count >= seed.length) {
			return [ (seed ?? []) ];
		}

		const out = [];
		for (let i = 0; i < seed.length; i += count) {
			out.push(seed.slice(i, i + count));
		}

		return out;
	}

	/**
	 * @desc Divide array @seed into a collection of length @count
	 * @param {Array} seed
	 * @param {Number} count
	 * @param {Boolean} fitCount If collection ends up being smaller than @count, fill in with undefined
	 *
	 * @example Chunk([1,2,3,4,5,6], 2) => [ [1,2,3], [4,5,6] ]
	 * @example Chunk([1,2,3,4,5,6], 3) => [ [1,2], [3,4], [5,6] ]
	 * @example Chunk([1,2,3,4,5,6,7], 3) => [ [1,2], [3,4], [5,6], [7] ]
	 * @example Chunk([1,2,3,4,5,6], 4, true) => [ [1,2], [3,4], [5,6], undefined ]
	 */
	static Chunk(seed, count, fitCount = undefined) {
		if (!seed || !seed.length || !count || count <= 1) {
			const ret = [ (seed ?? []) ];
			if (fitCount && count) {
				ret.length = count;
			}
			return ret;
		}

		const collateCount = Math.ceil(seed.length / count);
		const res = this.Collate(seed, collateCount);
		if (fitCount) {
			res.length = count;
		}
		return res;
	}
}