import React from "react";
import Profile from "../models/Profile";
import LiveApiClient from "../api/LiveApiClient";
import IdentityClient from "../api/IdentityClient";
import { Cache } from "../util/Cache.util";

const AuthContext = React.createContext({
	token: undefined,
	currentUser: undefined,
	profile: undefined,
	organizationMember: undefined,
	setOrganizationMember: () => Promise,
	login: () => Promise,
	logout: () => Promise,
	updateCurrentUser: () => Promise,
	updateUserCatalogs: () => Promise,
	requestPin: () => Promise,
	clearStore: () => {},
});

export const useAuth = () => {
	const context = React.useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within a AuthProvider");
	}
	return context;
};

export const AuthProvider = ({ children }) => {
	const storedToken = undefined;
	const [token, setToken] = React.useState(storedToken || undefined);

	const storedCurrentUser = JSON.parse(localStorage.getItem("currentUser"));
	const [currentUser, setCurrentUser] = React.useState(storedCurrentUser || undefined);


	//profile is never stored into localStorage anywhere
	const storedProfile = new Profile(JSON.parse(localStorage.getItem("profile")));
	const [profile] = React.useState(storedProfile || undefined);

	const [organizationMember, setOrganizationMember] = React.useState(undefined);

	const requestPin = async username => {
		const { data } = await IdentityClient.post("otp-login-request", { username });
		return data;
	};

	const login = async ({ username, password, pin, otp, mfa }, ignore_errors = false) => {
		const response = await IdentityClient.post("authorize", {
			username,
			password,
			pin,
			otp,
			mfa,
		});

		if (response.data.success && response.data.token && response.data.token !== "undefined") {
			setToken(response.data.token);
			const user = response.data.identity;
			delete user.meta_json;
			delete user.meta;

			setCurrentUser(user);
			localStorage.setItem("currentUser", JSON.stringify(user));
		} else if (!ignore_errors && response.data.errors) {
			logout(true);
		}
		return response?.data;
	};

	const getUserCatalogs = async () => {
		const paths = encodeURIComponent(JSON.stringify({
			id: true,
			label: true,
			title: true,
			uri: true,
			initial_uri: true,
			options: true,
			account: {
				id: true,
				name: true,
				logo: true,
			},
		}));
		const filter = encodeURIComponent(JSON.stringify([{
			"property": "{access.id}",
			"operator": "!=",
			"value": null
		}]));
		const response = await LiveApiClient.get(`Catalog?paths=${paths}&filter=${filter}`);
		return response.data;
	};

	const logout = async (skipRedirect = false) => {
		setToken(undefined);
		setCurrentUser(undefined);
		await IdentityClient.post("deleteToken");
		clearStore();
		if (!skipRedirect) {
			window.location = "/";
		}
	}

	const clearStore = () => {
		localStorage.removeItem('catalog_invitation:data');
		localStorage.removeItem("currentUser");
		localStorage.removeItem("currentCatalog");
		localStorage.removeItem("profile");
		localStorage.removeItem("background");
		localStorage.removeItem("foreground");
		localStorage.removeItem("throttle");
		localStorage.removeItem("organization_member:data");
		localStorage.removeItem("customer");
		Cache.Local().set("has-b2c-catalog", undefined);
	}

	const updateCurrentUser = (userNewData) => {
		const updatedUser = Object.assign({}, currentUser, userNewData);
		setCurrentUser(updatedUser);
		localStorage.setItem("currentUser", JSON.stringify(updatedUser));
	};
	const updateUserCatalogs = async () => {
		const user = currentUser;
		user.catalogs = await getUserCatalogs();
		setCurrentUser(user);
		localStorage.setItem("currentUser", JSON.stringify(user));
	};

	const value = {
		token,
		currentUser,
		profile,
		organizationMember,
		setOrganizationMember,
		login,
		logout,
		updateCurrentUser,
		updateUserCatalogs,
		requestPin,
		clearStore,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
