export class Cache
{
	static #localInstance;
	static #sessionInstance

	#store;

	/**
	 *
	 * @returns {Cache}
	 */
	static Local() {
		if (this.#localInstance) return this.#localInstance;

		this.#localInstance = new Cache();
		this.#localInstance.#store = localStorage;
		return this.#localInstance;
	}

	/**
	 *
	 * @returns {Cache}
	 */
	static Session() {
		if (this.#sessionInstance) return this.#sessionInstance;

		this.#sessionInstance = new Cache();
		this.#sessionInstance.#store = sessionStorage;
		return this.#sessionInstance;
	}

	set(key, value) {
		if (!key) throw new Error("Key required for Cache.Set()");
		let valueToSave = value;
		if (value instanceof Map) {
			valueToSave = Object.fromEntries(value);
		}

		valueToSave
			? this.#store.setItem(key, typeof value === "string" ? value : JSON.stringify(valueToSave))
			: this.#store.removeItem(key);
		return value;
	}

	get(key) {
		const item = this.#store.getItem(key);
		if (!item) return;
		try {
			return JSON.parse(item);
		}
		catch (e) {
			return item;
		}
	}
}